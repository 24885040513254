<template>
  <div class="wrapper">
    <section style="padding: 0;" class="carousel-section">
      <div id="carouselExampleIndicators" class="carousel slide" data-bs-interval="3000">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <router-link to="/join">
              <img style="object-fit:contain;width: 100%;" class="d-block"
                src="/img/carousel/BANNER01.jpg">
            </router-link>
          </div>
          <div class="carousel-item">
            <router-link to="/deposit">
              <img style="object-fit:contain;width: 100%;" class="d-block"
                src="/img/carousel/BANNER02.jpg">
            </router-link>
          </div>
          <div class="carousel-item">
            <router-link
              :to="{ name: aviatorCrashGameUrl, params: { IsDemo: 0, providerID: aviatorProviderID, gameName: aviatorGameName, gameID: aviatorGameID } }">
              <img style="object-fit:contain;width: 100%;" class="d-block"
                src="/img/carousel/aviator.png">
            </router-link>
          </div>
          <div class="carousel-item">
            <router-link
              :to="{ name: jetxCrashGameUrl, params: { IsDemo: 0, providerID: jetxProviderID, gameName: jetxGameName, gameID: jetxGameID } }">
              <img style="object-fit:contain;width: 100%;" class="d-block" src="/img/carousel/jetx.png">
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Slider",
  data() {
    return {
      aviatorProviderID: 7,
      aviatorGameID: "aviator",
      aviatorGameName: "Aviator",
      aviatorCrashGameUrl: "aviator",
      jetxProviderID: 5,
      jetxGameID: "JetX",
      jetxGameName: "Jet X",
      jetxCrashGameUrl: "jetx",

      currentSlide: 0,
      slides: null,
      slideInterval: null,
    }
  },
  mounted() {
    this.slides = document.querySelectorAll('.carousel-item');
    this.startAutoSlide();
  },
  beforeDestroy() {
    clearInterval(this.slideInterval);
  },
  methods: {
    setSportMenu(sport_id, sport_name) {
      this.sport_name = sport_name;
      this.$store.dispatch("setSportID", sport_id);
      this.$store.dispatch("setCurrentPage", sport_name);
      console.log(this.$store.state.sport_id)
      this.$router.push({ name: 'sport', params: {} });
    },
    startAutoSlide() {
      this.slideInterval = setInterval(() => {
        this.nextSlide();
      }, 5000); // 3 seconds interval
    },
    nextSlide() {
      this.slides[this.currentSlide].classList.remove('active');
      this.currentSlide = (this.currentSlide + 1) % this.slides.length;
      this.slides[this.currentSlide].classList.add('active');
    },
    prevSlide() {
      this.slides[this.currentSlide].classList.remove('active');
      this.currentSlide =
        (this.currentSlide - 1 + this.slides.length) % this.slides.length;
      this.slides[this.currentSlide].classList.add('active');
    },
  },
}
</script>
